<template>

  <div>
    <Search @change="setFilter" />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <List :orders="orders" />
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="list"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip,
  BRow, BCol, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-unresolved
import List from '@components/orders/Echouees.vue'
import Search from '@components/orders/Search.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    List,
    Search,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      orders: [],
      tableColumns: [],
      avatarText,
      currentPage: 10,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 25,
      filter: {},
      searchQuery: '',
    }
  },
  async mounted() {
    await this.list()
  },
  methods: {
    setFilter(filter, search) {
      this.filter = filter
      this.searchQuery = search || ''
      this.list(1)
    },
    async list(e) {
      this.currentPage = e
      const option = {
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      }
      const {
        orders,
        totalPages,
        totalItems,
        itemsPerPage,
      } = await this.$http.get('/admin/orders/failed', { params: { ...option, filter: this.filter, search: this.searchQuery } })
      this.orders = orders
      this.totalPages = totalPages
      this.totalItems = totalItems
      this.itemsPerPage = itemsPerPage
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
