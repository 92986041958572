<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="orders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Aucune commande trouvée"
      >

        <!-- Column: User -->
        <template #cell(order)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :variant="`light-${resolveUserStatusVariant(data.item.order.shipping.type)}`"
                :to="{ name: 'commandeDetail', params: { id: data.item.order.number } }"
              />
            </template>
            <b-link
              :to="{ name: 'commandeDetail', params: { id: data.item.order.number } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.order.shipping.first_name }} {{ data.item.order.shipping.last_name }}
            </b-link>
            <small class="text-muted">{{ data.item.order.number }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(price)="data">
          <b-badge
            pill
            class="text-capitalize bg-secondary bg-lighten-3"
          >
            {{ data.item.order.total }} €
          </b-badge>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.order.status)}`"
            class="text-capitalize"
          >
            {{ data.item.order.status }}
          </b-badge>
        </template>

        <template #cell(date)="data">
          <span>
            {{ $moment(data.item.order.date_created).format('DD-MM-YYYY') }}
          </span>
        </template>

        <template #cell(product)="data">
          <span
            v-b-tooltip.hover
            :title="data.item.order.line_items.map(item => `${item.quantity} x ${item.name}`).join('\n')"
            variant="gradient-primary"
          >
            {{ data.item.order.line_items.map(l => l.quantity).reduce((a, b) => a + b, 0) > 1 ? `${data.item.order.line_items.map(l => l.quantity).reduce((a, b) => a + b, 0) } articles` : `${data.item.order.line_items.map(l => l.quantity).reduce((a, b) => a + b, 0) } article` }}
          </span>
        </template>

        <template #cell(country)="data">
          <img
            style="width: 30px;"
            :src="require(`@/assets/images/flags/${imageCountry(data.item.order.number.slice(0, 2))}.svg`)"
          >
        </template>

        <template #cell(user)="data">
          <div v-if="data.item.sav && data.item.sav.users">
            <b-avatar
              v-for="(user, key) of data.item.sav.users"
              :key="key"
              variant="light-primary"
              :text="`${user.split(' ')[0][0]}.${user.split(' ')[1][0]}`"
            />
          </div>
        </template>
        <template #cell(historic)="data">
          <span v-if="data.item.sav && data.item.sav.historic">
            {{ $moment(data.item.sav.historic[data.item.sav.historic.length - 1]).format('DD-MM-YYYY') }}
          </span>
        </template>

        <!-- Column: Status -->
        <!-- <template #cell(country)="data">
          <img
            style="width: 30px;"
            :src="require(`@/assets/images/flags/${data.item.country.toLowerCase()}.svg`)"
          >
        </template> -->

        <!-- Column: Actions -->
        <!-- <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template> -->

      </b-table>
      <!-- <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div> -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BMedia,
  BBadge, BAvatar, BLink, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,

    // vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      avatarText,
    }
  },
  async mounted() {
    const tableColumns = [
      { key: 'order', label: 'Client', sortable: true },
      { key: 'price', label: 'Montant', sortable: true },
      { key: 'status', label: 'Statut', sortable: true },
      { key: 'date', label: "Date d'achat", sortable: true },
      { key: 'product', label: 'Produits', sortable: true },
      { key: 'country', label: 'Pays', sortable: true },
      { key: 'user', label: 'SAV', sortable: true },
      { key: 'user', label: 'SAV', sortable: true },
      { key: 'historic', label: 'Last Action', sortable: true },
    ]
    this.tableColumns = tableColumns
  },
  methods: {
    resolveUserStatusVariant(type) {
      if (type === 'admin') return 'success'
      if (type === 'user') return 'secondary'
      return 'primary'
    },
    imageCountry(type) {
      if (type === 'FR') return 'france'
      if (type === 'DE') return 'allemagne'
      if (type === 'ES') return 'espagne'
      if (type === 'IT') return 'italie'
      if (type === 'PT') return 'portugal'
      return 'france'
    },
    resolveStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'sended') return 'secondary'
      if (status === 'failed') return 'warning'
      if (['cancelled', 'on-hold'].includes(status)) return 'danger'
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
